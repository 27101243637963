// import axios from 'axios';
import axiosApi from '@/api/index';

// let urlPath = '/api/rooms';
let urlPath = '/api/territories';

function get(params) {
	params = params || {};
	params.t = new Date().getTime();
	return axiosApi.get(`${urlPath}`, { params });
}

function getOne(id) {
	return axiosApi.get(`${urlPath}/${id}`);
}

function create(req) {
	return axiosApi.post(`${urlPath}`, req);
}

function update(req) {
	return axiosApi.put(`${urlPath}`, req);
}

function remove(id) {
	return axiosApi.delete(`${urlPath}/${id}`);
}

export default { get, getOne, create, update, remove };

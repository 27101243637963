<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2 class="txtL">메모관리</h2>
				<a @click="myInfo()" class="btnBack">실별 관리로 이동</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- contents -->
		<div class="contents">
			<!-- memoList -->
			<div class="memoList">
				<ul v-if="total > 0">
					<li v-for="(memo, index) in memoList" :key="index">
						<a @click="onMemo(memo.id)">
							<!-- location -->
							<div class="location">
								<span>{{ memo.buildingName }}</span>
								<span v-if="memo.floorId != null">{{ memo.floorName }}</span>
								<span v-if="memo.roomId != null">{{ memo.roomName }}</span>
								<span v-if="memo.objectPinId != null">{{ memo.objectName }}</span>
								<span v-if="memo.locations && memo.locations.length > 1" class="locationCount"
									>외 {{ memo.locations.length - 1 }}개</span
								>
							</div>
							<!--// location -->
							<!-- memo -->
							<div class="memo">
								<div class="user">
									<div class="imgArea">
										<p><img :src="`/file/${memo.creatorImageId}`" alt="" v-if="memo.creatorImageId" /></p>
									</div>
									<p>{{ memo.creatorName }}</p>
								</div>
								<div class="user" v-if="memo.projectName != null">
									<span> <strong>[프로젝트]</strong> </span>
									<span class="title">&nbsp;{{ memo.projectName }}</span>
									<span class="interval">
										&nbsp;({{ memo.projectBeginDate | dateFormatYear }}~{{ memo.projectEndDate | dateFormatYear }})
									</span>
								</div>
								<div class="user" v-if="memo.schedule.name">
									<span>
										<strong>[스케줄]</strong>
									</span>
									<span class="title">&nbsp;{{ memo.schedule.name }}</span>
									<span class="interval"
										>&nbsp;({{ setIntervalStr(memo.schedule.intervalType) }} {{ setDateFormat(memo.schedule) }})</span
									>
								</div>
								<p class="date">
									<span>{{ memo.createDate | dateFormatYear }}</span>
									<span>{{ memo.createDate | dateFormatMonth }}</span>
								</p>
								<p>
									{{ memo.content }}
								</p>
							</div>
							<!--// memo -->
						</a>
						<!-- imgList -->
						<div class="devImgList">
							<div class="inBox">
								<p v-for="(memoFile, index) in memo.memoFiles" :key="index">
									<img :src="`/file/${memoFile.fileId}`" alt="" @click="onOpenImage(memoFile.fileId, memo.memoFiles)" />
								</p>
							</div>
						</div>
						<!--// imgList -->
						<!-- viewInfo -->
						<div class="viewInfo">
							<a class="btnComment">{{ memo.commentCount }}</a>
							<a class="btnViews">{{ memo.views }}</a>
							<a class="btnBildMove" @click="onMove(memo)">이동하기</a>
						</div>
						<!--// viewInfo -->
					</li>
				</ul>
				<ul v-else>
					<h3>최신글</h3>
					<!-- noData -->
					<div class="noData">
						<p>등록된 최신글이 없습니다.</p>
					</div>
				</ul>
			</div>
			<!--// memoList -->
		</div>
		<!--// contents -->
		<PopUpImage ref="popUpImage"></PopUpImage>
	</div>
	<!--// container -->
</template>
<script>
import memo from '@/api/memo';
import room from '@/api/room';
import { dateFormatYear, dateFormatMonth, setIntervalStr } from '@/utils/cmm';
import PopUpImage from '../popup/popUpImage';

export default {
	components: { PopUpImage },
	mounted() {
		this.onGet();
		this.$EventBus.$on('paging', () => {
			if (this.total <= this.offset) return;
			this.onGet();
		});
	},
	beforeDestroy() {
		this.$EventBus.$off('paging');
	},
	filters: {
		dateFormatYear,
		dateFormatMonth,
	},
	data() {
		return {
			memoList: [],
			offset: 0,
			limit: 10,
			total: '',
			isAtTheBottom: false,
		};
	},
	methods: {
		async onGet() {
			let params = {
				offset: this.offset,
				limit: this.limit,
				orderBy: 'mine',
			};
			try {
				let res = await memo.get(params);
				this.total = res.total;
				if (this.total > 0) {
					res.data.forEach(p => this.memoList.push(p));
					this.offset += this.limit;
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
		myInfo() {
			this.$router.push({ name: 'user' });
		},
		onMemo(id) {
			this.$router.push({ name: 'memoDetail', params: { id: id, reqUrl: 'mine' } });
		},
		async onMove(memo) {
			if (memo.roomId) {
				let resRoom = await room.getOne(memo.roomId);
				if (resRoom.shapeType === 'all') {
					this.$router.push({ name: 'floorDetail', params: { id: memo.floorId } });
				} else {
					this.$router.push({ name: 'roomDetail', params: { id: memo.roomId, category: 'memo' } });
				}
			} else if (memo.floorId) {
				this.$router.push({ name: 'floorDetail', params: { id: memo.floorId } });
			} else {
				this.$router.push({ name: 'buildingDetail', params: { id: memo.buildingId } });
			}
		},
		onOpenImage(imageId, memoFiles) {
			this.$refs.popUpImage.onOpenImage(imageId, memoFiles);
		},
		setIntervalStr(intervalType) {
			return setIntervalStr(intervalType);
		},
		setDateFormat(schedule) {
			let date = schedule.beginDate;
			switch (schedule.intervalType) {
				case 'Y':
					date = this.$moment(date).format('M월 D일');
					break;
				case 'M':
					date = this.$moment(date).format('D일');
					break;
				case 'W':
					switch (this.$moment(date).day()) {
						case 0:
							date = '일요일';
							break;
						case 1:
							date = '월요일';
							break;
						case 2:
							date = '화요일';
							break;
						case 3:
							date = '수요일';
							break;
						case 4:
							date = '목요일';
							break;
						case 5:
							date = '금요일';
							break;
						case 6:
							date = '토요일';
							break;
					}
					break;
				case 'D':
					date = this.$moment(date).format('YYYY월 M월 D일');
					break;
			}
			return date;
		},
	},
};
</script>

<style scoped>
.locationCount {
	padding-left: 5px;
	background: none;
}
</style>

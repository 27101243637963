// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/memo';

async function getScheduleMemo(params) {
	return axiosApi.get(`${urlPath}/schedule`, { params });
}
async function get(params) {
	return axiosApi.get(`${urlPath}`, { params });
}
async function count() {
	let res = await axiosApi.get(`${urlPath}/count`);
	let count = res.total;
	return count;
}
async function getOne(id) {
	return axiosApi.get(`${urlPath}/${id}`);
}
async function remove(id) {
	return axiosApi.delete(`${urlPath}/${id}`);
}
async function create(params) {
	return axiosApi.post(`${urlPath}/`, params);
}
async function update(params) {
	return axiosApi.put(`${urlPath}/`, params);
}
export default { get, count, getOne, getScheduleMemo, remove, create, update };
